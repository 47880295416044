<template>
  <main class="main-page">
    <TheHero
      v-if="!isMobile"
      :main-page="true"
      :hero-title="heroTitle"
      :hero-image="heroImage"
      :buttons-list="heroButtons"
    />
    <TheHero
      v-else
      :main-page="true"
      :hero-title="heroTitle"
      :hero-image="heroImageMobile"
      :buttons-list="heroButtons"
    />

    <FilterMain v-if="!isMobile"/>
    <EstateResaleSection
      v-if="!isMobile"
      :do-update="false"
      title="Вторичная недвижимость"
      :resale-list="resaleSection"
    />
    <FormSection
      v-if="!isMobile"
      :title="formSectionTitle"
      :text="formSectionText"
      :image="isMobile ? formSectionImageMobile : formSectionImage"
    />
    <!-- <EstateNewSection
      v-if="!isMobile"
      :new-estate-list="newEstateList"
      :do-update="false"
    /> -->
    <ServicesSection v-if="!isMobile"/>
    <AboutUs :about-section="aboutSection" v-if="!isMobile"/>
    <ReviewsSection v-if="!isMobile"/>
    <NewsSection v-if="!isMobile"/>
  </main>
</template>

<script>
import TheHero from "@/components/TheHero.vue";
import FormSection from "@/components/FormSection.vue";
import ReviewsSection from "@/components/ReviewsSection.vue";
import EstateResaleSection from "@/components/EstateResaleSection.vue";
import AboutUs from "@/components/AboutUs.vue";
import ServicesSection from "@/components/ServicesSection.vue";
import FilterMain from "@/components/FilterMain.vue";
import NewsSection from "@/components/NewsSection.vue";

export default {
  components: {
    TheHero,
    FormSection,
    ReviewsSection,
    EstateResaleSection,
    AboutUs,
    ServicesSection,
    FilterMain
  },

  data() {
    return {
      isMobile: false
    };
  },

  mounted() {

    this.matchMedia();
  },

  methods: {
    keyUpdated(key) {
      this.updateKey = key;
    },

    matchMedia() {
      const mediaQuery = window.matchMedia("(max-width: 639px)");

      if (mediaQuery.matches) {
        this.isMobile = true;
      }
    }
  }
};
</script>

<script setup>
import { ref, onBeforeMount } from "vue";
import axios from "axios";
import EstateNewSection from "@/components/EstateNewSection.vue";

const page = ref([]);
const heroImage = ref("");
const heroImageMobile = ref("");
const heroTitle = ref("");
const heroButtons = ref([]);
const formSectionTitle = ref("");
const formSectionText = ref("");
const formSectionImage = ref(null);
const formSectionImageMobile = ref(null);
const aboutSection = ref([]);

const resaleSection = ref([]);
const newEstateList = ref([]);

onBeforeMount(async () => {
  const { data } = await axios.get("/api/getPageElements?name=main");
  const mainPage = (page.value = data.blocks);

  heroImage.value = mainPage.main_banner.elements.image.source.image;
  heroImageMobile.value = mainPage.main_banner.elements.image_mobile.source.image;
  heroTitle.value = mainPage.main_banner.elements.title.source;
  heroButtons.value = mainPage.main_banner.elements.links.source;

  formSectionTitle.value = mainPage.feedback.elements.title.source;
  formSectionText.value = mainPage.feedback.elements.description.source;
  formSectionImage.value = mainPage.feedback.elements.image.source.image_thumb;
  formSectionImageMobile.value = mainPage.feedback.elements.image_mobile.source.image_thumb;

  aboutSection.value = mainPage.about.elements;

  axios
    .all([
      axios.get("/api/getOffersList", {
        params: {
          isZhk: 0,
          type: 1,
          limit: 8
        }
      }),
      axios.get("/api/getOffersList", {
        params: {
          isZhk: 1,
          limit: 8
        }
      })
    ])
    .then(([resaleEstate, newEstate]) => {
      resaleSection.value = resaleEstate.data.data;
      newEstateList.value = newEstate.data.data;
    });
});
</script>

<style lang="scss" scoped>
.main-page {
  $that: &;

  overflow: hidden;

  &__container {
    @extend %container;
  }

  h1 {
    font-size: 24px;
    color: $color_primary-dark;

    @include phone($only: true) {
      color: red;
    }
  }

  &__projects-list {
    color: $color_error;
    display: flex;
    justify-content: space-between;
  }

  &__projects-item {
    padding: 20px;

    div + div {
      padding-top: 12px;
    }
  }
}
</style>
